import React, { useState,useEffect } from 'react';
import './App.css';
import './password.css';
import { music } from './music';
import { music2 } from './music2';

const PasswordPage = ({ onPasswordSubmit }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onPasswordSubmit(password);
  };

  return (
<div className="password-page">
  <h2>Enter Password (输入密码)</h2>
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="form-input"
      />
    </div>
    <div className="form-group">
      <button type="submit" className="submit-button">Submit (登录)</button>
    </div>
  </form>
</div>

  );
};

const App = () => {

  const [timeElapsed, setTimeElapsed] = useState(0); // Elapsed time in seconds
  const [counter, setCounter] = useState(0);
  const [differences, setDifferences] = useState(5);
  const [gameCompleted, setGameCompleted] = useState(false);
  const [gameCompletedFailed, setGameCompletedFailed] = useState(false);

  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadedImage2, setUploadedImage2] = useState(null);
  const [circles, setCircles] = useState([]);
  const [crosses, setCrosses] = useState([]);
  const [lives, setLives] = useState(0); // Add this line
  const [hints, setHints] = useState([]);


  useEffect(() => {
    let timer;

    if (!gameCompleted) {
      timer = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1);
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [gameCompleted]);

  // Format the time remaining as mm:ss
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  const franklinDifferenceLocations = [
    { x: 117, y: 288.375 },
    { x: 21, y: 55.375 },
    { x: 220, y: 84.375 },
    { x: 109, y: 153.375 },
    { x: 88, y: 114.375 },
  ];

  const leoDifferenceLocations = [
    { x: 169, y: 114.625 },
    { x: 178, y: 193.625 },
    { x: 74, y: 184.625 },
    { x: 57, y: 116.625 },
    { x: 12, y: 20.625 },
  ];
  const franklinleoDifferenceLocations = [
    { x: 79, y: 229.125 },
    { x: 107, y: 136.625 },
    { x: 127, y: 50.125 },
    { x: 45, y: 167.875 },
    { x: 18.5, y: 94.625 },
  ];
  const franklinseatDifferenceLocations = [
    { x: 83.5, y: 76.125 },
    { x: 84.5, y: 153.125 },
    { x: -2.5, y: 64.125 },
    { x: 179.5, y: 62.125 },
    { x: 151.5, y: 14.125 },
  ];

  const newfranklinseatDifferenceLocations = [
    { x: 70.5, y: 220 },
    { x: 40.5, y: 151 },
    { x: 56.5, y: 41 },
    { x: 118.5, y: 191 },
    { x: 138, y: 229.5 },
  ];


  let fullDifferenceLocations = [
    franklinDifferenceLocations,
    leoDifferenceLocations,
    franklinleoDifferenceLocations,
    franklinseatDifferenceLocations,
    newfranklinseatDifferenceLocations
  ];
  const numOfGames = fullDifferenceLocations.length;
  const [differenceLocations, setDifferenceLocations] = useState(fullDifferenceLocations[0]);
  const [differenceLocations0, setDifferenceLocations0] = useState(fullDifferenceLocations[0]);

  const [passwordEntered, setPasswordEntered] = useState(false);

  let inDev = process.env.NODE_ENV === "development"? true: false;
  //inDev = false;

  const handlePasswordSubmit = (password) => {
    // Here, you can perform password validation logic
      const serverUrl = 'https://xyz.chocofrog.com/api/login';
      //const serverUrl = 'http://localhost:5000/login';
      const data = {
        token: password,
      };

    fetch(`${serverUrl}`, {
      method: 'POST',
      //mode: 'no-cors',
      headers: {
          'Content-Type': 'application/json' // Set the Content-Type header to indicate JSON data
      },
      body: JSON.stringify(data), // Include the request body data
    })
    .then(response => {
      console.log(response);
      if (!response.ok) {
        throw new Error('Request failed');
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      setPasswordEntered(true);
    })
    .catch((error) => {
      // Handle error
      console.log(error);
      alert('Invalid password');
    });    
  };
  const resetState = () => {
    setDifferences(5);
    setGameCompleted(false);
    setGameCompletedFailed(false);
    setCircles([]);
    setCrosses([]);
    setLives(0);
    setTimeElapsed(0);

  };
  const handleResetGame = () => {
    resetState();
    setDifferenceLocations(differenceLocations0);
  };
  const handleNewGame = () => {
    resetState();
    setUploadedImage(null);
    setUploadedImage2(null);
    const index = (counter+1) % numOfGames;
    setDifferenceLocations(fullDifferenceLocations[index]);
    setDifferenceLocations0(fullDifferenceLocations[index]);
  setCounter(counter +1);

  };

  const handleHintClick = () => {
    if (differenceLocations.length > 0) {
      const randomIndex = Math.floor(Math.random() * differenceLocations.length);

      const hint = differenceLocations[randomIndex];
      setHints([...hints, hint]);
      // console.log('differenceLocations: ' ,differenceLocations);
      console.log('hint: ' ,hint);
      setTimeout(() => {
        setHints(hints.filter(h => h !== hint));
      }, 2000);
      if (music.rotate) {
        music.rotate();
      }
      
    }
  };
  const handleDifferenceClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const circleDiameter = 20; // Change this value if you change the circle's size
    const x = e.clientX - rect.left - circleDiameter / 2;
    const y = e.clientY - rect.top - circleDiameter / 2;
    console.log("x: ", x, "y: ", y);
    if (gameCompletedFailed || gameCompleted) {
      return
      }
      // console.log("differenceLocations: ", differenceLocations);
    const isNearDifference = differenceLocations.some(location =>
      Math.abs(location.x - x) < 7 && Math.abs(location.y - y) < 7
    );
    // console.log("isNearDifference: ", isNearDifference);

    if (isNearDifference) {

      setDifferenceLocations(prevLocations =>
        prevLocations.filter(location => !(Math.abs(location.x - x) < 7 && Math.abs(location.y - y) < 7))
      );

      setCircles([...circles, { id: Date.now(), x: x, y: y }]);
      if (differences > 0) {
        setDifferences(differences - 1);
        if (music.clear) {
          music.clear();
        }
      }
      if (differences - 1 === 0) {
        setGameCompleted(true);
        if (music.start) {
          music.start();
        }
        if (music2.success) {
          music2.success();
        }
      }
    } else {
      if (lives < 100) { // Subtract lives when user clicks wrong location
        setLives(lives + 1);
      } else {
        setLives(0);
        setGameCompletedFailed(true); // End game when no lives left
        if (music.gameover) {
          music.gameover();
        }
      }

      const cross = { id: Date.now(), x: x, y: y };
      setCrosses(crosses => [...crosses, cross]);
      setTimeout(() => {
        setCrosses(crosses => crosses.filter(c => c.id !== cross.id));
      }, 1000);
      if (music.move) {
        music.move();
      }
      if (music2.fail) {
        music2.fail();
      }
    }
    
  };

  const handleImageUpload = (event) => {

    //TODO: send image to server to generate base and new images
    //TODO: get a pair of images and their difference locations from server

    if (event.target.files?.length == 1) {
    //locally, we just call a test script to generate the new image
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('image', file);
      const serverUrl = inDev? 'http://localhost:5000/process_image': 'https://xyz.chocofrog.com/api/process_image';
    fetch(`${serverUrl}`, {
      method: 'POST',
      body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
      // Handle the parsed data
      const { base_image, modified_image, difference_locations, baseImageDataUrl, newImageDataUrl } = data;
      const convertedDifferenceLocations = difference_locations.map(([x, y]) => ({ x, y }));

      // console.log('Base Image:', base_image);
      // console.log('Modified Image:', modified_image);
      // console.log('Difference Locations:', difference_locations);
      resetState();
      setDifferenceLocations(convertedDifferenceLocations);
      setDifferenceLocations0(convertedDifferenceLocations);
      setUploadedImage(baseImageDataUrl);
      setUploadedImage2(newImageDataUrl);
      // fullDifferenceLocations.push(difference_locations);
      // console.log('fullDifferenceLocations len:', fullDifferenceLocations.length);
    })  
      .catch((error) => {
        // Handle error
        console.log(error);
      });    
    // const reader = new FileReader();
    // reader.onload = (e) => {
    //   const fileContent = e.target.result;  
    //   // Parse the file content as JSON
    //   const parsedContent = JSON.parse(fileContent);

    //   // Map the parsed content into the desired format
    //   const newfranklinseatDifferenceLocations = parsedContent.map(([x, y]) => ({
    //     x,
    //     y,
    //   }));  
    //   // Output the result
    //   console.log(newfranklinseatDifferenceLocations);
    // };
    // reader.readAsText(file);
  
      return;
    }
    const file0 = event.target.files?.[0];
    if (file0) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        setUploadedImage(imageDataUrl);
      };
      reader.readAsDataURL(file0);
    }

    const file1 = event.target.files?.[1];
    if (file1) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageDataUrl = reader.result;
        setUploadedImage2(imageDataUrl);
      };
      reader.readAsDataURL(file1);
    }
  };

  // const imageSrc1 = counter % 2 ? '/leo-new.png' : '/franklin-new.png';
  // const imageSrc2 = counter % 2 ? '/leo-copy.png' : '/franklin-copy.png';

  const index = counter % numOfGames;
  const imageSrc1 = `/${index}-new.png`
  const imageSrc2 = `/${index}-copy.png`

  return (
    <div className="container">
      {(!inDev && !passwordEntered) ? (
        <PasswordPage onPasswordSubmit={handlePasswordSubmit} />
      ) : (
        <><h1>Spot the Difference</h1>
      {gameCompleted && (
        <div className="game-completed">
          Congratulations (太棒了)!
          <span role="img" aria-label="Thumbs Up">👍</span>
        </div>
      )}
      {gameCompletedFailed && (
        <div className="game-completed-failed">
          Ooop.. Call Franklin (217-417-171x) for help!
        </div>
      )}
           {gameCompletedFailed&&       <button onClick={handleResetGame} className="next-game-button">Reset Game (重来)</button> }
           {gameCompleted &&       <button onClick={handleNewGame} className="next-game-button">New Game (下一个)</button> }
           {gameCompleted && (
        <div>Elapsed Time: {formatTime(timeElapsed)}</div>
      )}
      {!gameCompleted && <div>Timer: {formatTime(timeElapsed)}</div>}
      <div className="content">
      <div className="image-container" style={{position: 'relative'}}>
        {uploadedImage ? (
            <img src={uploadedImage} alt="Uploaded" onClick={handleDifferenceClick}/>
  
          ) : (
            <img src={imageSrc1}  alt="Image A" onClick={handleDifferenceClick} />
          )}
          {circles.map((circle) => (
              <div
                key={circle.id}
                className="circle"
                style={{ top: circle.y, left: circle.x }}
              />
            ))}
                        {crosses.map((cross) => (
          <div
            key={cross.id}
            className="cross"
            style={{ top: `${cross.y}px`, left: `${cross.x}px` }}
          />
        ))}
              {/* Render hint circles */}
{hints.map((hint, index) => (
  <div
    key={index}
    className="greencircle"
    style={{
      position: 'absolute', 
      top: `${hint.y}px`, 
      left: `${hint.x}px`, 
        }}

  />
))}
        </div>
        <div className="image-container" style={{position: 'relative'}}>
        {uploadedImage2 ? (
            <img src={uploadedImage2} alt="Uploaded" onClick={handleDifferenceClick} />
          ) : (
            <img src={imageSrc2}  alt="Image B" onClick={handleDifferenceClick}/>
          )}
                    {circles.map((circle) => (
              <div
                key={circle.id}
                className="circle"
                style={{ top: circle.y, left: circle.x }}
              />
            ))}
            {crosses.map((cross) => (
          <div
            key={cross.id}
            className="cross"
            style={{ top: `${cross.y}px`, left: `${cross.x}px` }}
          />
        ))}
              {/* Render hint circles */}
{hints.map((hint, index) => (
  <div
    key={index}
    className="greencircle"
    style={{
      position: 'absolute', 
      top: `${hint.y}px`, 
      left: `${hint.x}px`, 
    }}

  />
))}
        </div>
      </div>
      <div className="score">
        Differences Found: {5-differences}/5
        <span className="lives">#Touches: {lives}</span>
      </div>
      <div className="button-container">

      {!gameCompleted && differences > 0 && (
        <button onClick={handleHintClick}>
          Hint (提示)
        </button>
      )}
      {!gameCompleted && (<button onClick={handleNewGame}>Next Game (下一个)</button>)} 
      </div>
      <label htmlFor="uploadButton" className="upload-label">
            <button id="uploadButton" onClick={() => document.getElementById('uploadInput')?.click()}>Create your own game (使用新图片)</button>
            <input type="file" accept="image/*" id="uploadInput" onChange={handleImageUpload} style={{ display: 'none' }} />
      </label>
      </>
    )}
    </div>
  );
};

export default App;
