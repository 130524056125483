// 使用 Web Audio API
const AudioContext = (
  window.AudioContext ||
  window.webkitAudioContext ||
  window.mozAudioContext ||
  window.oAudioContext ||
  window.msAudioContext
);

const music2 = {};

(() => {
  const url = '/output.mp3';
  const context = new AudioContext();
  const req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'arraybuffer';

  req.onload = () => {
    context.decodeAudioData(req.response, (buf) => { // 将拿到的audio解码转为buffer
      const getSource = () => { // 创建source源。
        const source = context.createBufferSource();
        source.buffer = buf;
        source.connect(context.destination);
        return source;
      };

      music2.success = () => { // 旋转
        getSource().start(0, 0.2471, 2.8);
      };


      music2.fail = () => { // 旋转
        getSource().start(0, 3.2471, 2.8);
      };
    },
    (error) => {
      if (window.console && window.console.error) {
        window.console.error(`audio: ${url} read err`, error);
      }
    });
  };

  req.send();
})();

module.exports = {
  music2,
};

